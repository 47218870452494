var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        centered: "",
        closeOnBackdrop: true,
        title:
          _vm.$t("lk.videoEncrypt.RemoveConfirmModal.title") +
          (_vm.video.title || "") +
          "?",
        show: _vm.show,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        attrs: {
          label:
            _vm.$t("lk.videoPublic.RemoveConfirmModalText") +
            (_vm.video.filename || ""),
          placeholder:
            _vm.$t("lk.videoPublic.RemoveConfirmModalText") +
            (_vm.video.filename || ""),
          type: "text",
        },
        model: {
          value: _vm.videoId,
          callback: function ($$v) {
            _vm.videoId = $$v
          },
          expression: "videoId",
        },
      }),
      _c(
        "div",
        { staticClass: "RemoveConfirmModal__btnBlock" },
        [
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "shadow-none RemoveConfirmModal__btn",
              attrs: {
                color: "success",
                loading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.disabledForm,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.remove.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("lk.videoEncrypt.RemoveConfirmModal.btnSubmit")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none RemoveConfirmModal__btn",
              attrs: { color: "primary", disabled: _vm.isLoading },
              on: { click: _vm.close },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("lk.videoEncrypt.RemoveConfirmModal.btnCancel")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }