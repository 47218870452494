var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "videoEncryptTable" },
    [
      _c("UploadVideoModal", {
        attrs: { show: _vm.showUploadVideoModal, uri: _vm.uri, isPublic: true },
        on: {
          close: function ($event) {
            _vm.showUploadVideoModal = false
          },
          uploaded: function ($event) {
            return _vm.getItems()
          },
        },
      }),
      _c("RemoveConfirmPublicModal", {
        attrs: { show: _vm.showRemoveConfirm, video: _vm.selectedItem },
        on: {
          close: function ($event) {
            _vm.showRemoveConfirm = false
          },
          success: function ($event) {
            return _vm.getItems()
          },
        },
      }),
      _vm.showPlayerModal
        ? _c("PlayerPublicModal", {
            attrs: {
              video: _vm.selectedItem,
              show: _vm.showPlayerModal,
              title: _vm.playerModalTitle,
            },
            on: {
              close: function ($event) {
                _vm.selectedItem = {}
                _vm.showPlayerModal = false
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "videoEncryptTable__header" },
        [
          _c(
            "CLoadingButtonCustom",
            {
              attrs: { color: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.getItems()
                },
              },
            },
            [_vm._v(" Reload ")]
          ),
          _vm.loadingTable
            ? _c("CSpinner", {
                staticClass: "ml-3",
                attrs: { color: "primary" },
              })
            : _vm._e(),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "ml-auto videoEncryptTable__header-btn",
              attrs: { color: "primary" },
              nativeOn: {
                click: function ($event) {
                  _vm.showUploadVideoModal = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lk.videoEncrypt.addVideo")))]
          ),
        ],
        1
      ),
      _c(
        "CCard",
        {
          staticClass: "shadow-none videoEncryptTable__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _c("CCardBody", { staticClass: "h-100 d-flex flex-column" }, [
            _c("div", { staticClass: "table-wrap" }, [
              _c(
                "table",
                { staticClass: "table mb-0 videoEncryptTable__table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("lk.videoPublic.file")))]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    { attrs: { color: "primary" } },
                    _vm._l(_vm.items, function (item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", { staticClass: "text-left" }, [
                          _c("p", [
                            _c("b", [_vm._v(_vm._s(item.dateTime))]),
                            _vm._v(" " + _vm._s(item.filename)),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("lk.videoPublic.url")) +
                                ": " +
                                _vm._s(item.playlist)
                            ),
                          ]),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-right text-nowrap" },
                          [
                            _c(
                              "CButton",
                              {
                                staticClass: "mr-1",
                                attrs: {
                                  color: "white",
                                  disabled: _vm.loadingTable,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectItem(item)
                                    _vm.playModal(item)
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-media-play" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loadingTable,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectItem(item)
                                    _vm.showRemoveConfirm = true
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }