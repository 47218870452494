var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "header d-flex" }, [
        _c("h1", { staticClass: "title mb-0 mr-3" }, [
          _vm._v(" " + _vm._s(_vm.$t(`lk.videoPublic.header`)) + " "),
        ]),
      ]),
      _c("CRow", [_c("VideoPublicTable")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }