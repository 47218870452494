<template>
  <div>
    <div class="header d-flex">
      <h1 class="title mb-0 mr-3">
        {{ $t(`lk.videoPublic.header`) }}
      </h1>
    </div>
    <CRow>
      <VideoPublicTable>
      </VideoPublicTable>
    </CRow>
  </div>
</template>

<script>
import VideoPublicTable from "@/components/videoPublic/VideoPublicTable";

export default {
  name: "PageVideoPublic",
  components: {
    VideoPublicTable
  },
  data() {
    return {};
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>
