<template>
  <div class="videoEncryptTable">
    <UploadVideoModal
      @close="showUploadVideoModal = false"
      :show="showUploadVideoModal"
      :uri="uri"
      :isPublic="true"
      @uploaded="getItems()"
    />
    <RemoveConfirmPublicModal
      @close="showRemoveConfirm = false"
      :show="showRemoveConfirm"
      :video="selectedItem"
      @success="getItems()"
    />
    <PlayerPublicModal
      @close="selectedItem = {}; showPlayerModal = false"
      :video="selectedItem"
      v-if="showPlayerModal"
      :show="showPlayerModal"
      :title="playerModalTitle"
    />
    <div class="videoEncryptTable__header">
      <CLoadingButtonCustom
        color="primary"
        @click.native="getItems()"
      >
        Reload
      </CLoadingButtonCustom>
      <CSpinner
        class="ml-3"
        v-if="loadingTable"
        color="primary"
      />
      <CLoadingButtonCustom
        class="ml-auto videoEncryptTable__header-btn"
        color="primary"
        @click.native="showUploadVideoModal = true"
        >{{$t('lk.videoEncrypt.addVideo')}}</CLoadingButtonCustom
      >
    </div>
    <CCard color="second-card-bg" class="shadow-none videoEncryptTable__card">
      <CCardBody class="h-100 d-flex flex-column">
        <div class="table-wrap">
          <table class="table mb-0 videoEncryptTable__table">
            <thead>
              <tr>
                <th>{{ $t('lk.videoPublic.file') }}</th>
                <th></th>
              </tr>
            </thead>

            <tbody color="primary">
              <tr v-for="(item, i) in items" :key="i">
                <td class="text-left">
                  <p><b>{{ item.dateTime }}</b> {{ item.filename }}</p>
                  <p>{{ $t('lk.videoPublic.url') }}: {{ item.playlist }}</p>
                </td>
                <td class="text-right text-nowrap">
                  <CButton
                    class="mr-1"
                    color="white"
                    :disabled="loadingTable"
                    @click="selectItem(item);playModal(item)"
                  >
                    <CIcon name="cil-media-play" />
                  </CButton>
                  <CButton
                    color="danger"
                    :disabled="loadingTable"
                    @click="selectItem(item);showRemoveConfirm = true"
                  >
                    <CIcon name="cil-x" />
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios"
import moment from 'moment'
import UploadVideoModal from '@/components/videoEncrypt/UploadVideoModal'
import RemoveConfirmPublicModal from '@/components/videoPublic/RemoveConfirmPublicModal'
import PlayerPublicModal from '@/components/videoPublic/PlayerPublicModal'

export default {
  name: "VideoPublicTable",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  components: {
    UploadVideoModal,
    RemoveConfirmPublicModal,
    PlayerPublicModal
  },
  data() {
    return {
      loadingTable: false,
      showUploadVideoModal: false,
      showPlayerModal: false,
      showRemoveConfirm: false,
      items: [],
      playerModalTitle: '',
      selectedItem: {},
      uri: '',
    };
  },
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loadingTable = true;

      await axios.get('/api/v1/video-public?uri=1').then(({data}) => {
        this.items = []
        this.uri = data.uri || ''
        data.data.forEach(el => {
          if(el.filename && el.playlist){
            this.items.push({
              ts: el.ts,
              dateTime: moment(el.ts * 1000).utc().format('DD.MM.YYYY HH:mm'),
              filename: el.filename,
              playlist: el.playlist,
              mime: el.mime || 'video/mp4',
              title: el.filename,
            })
          }
        })
      })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    selectItem(item) {
      this.selectedItem = item
    },

    playModal(item) {
      if(!item.playlist){
        return;
      }
      this.showPlayerModal = true
      this.playerModalTitle = item.title
    }
  },
};
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    top: -1px;
    z-index: 1;
  }
}

.videoEncryptTable {
  width: 100%;

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

     &__white {
      color: var(--white);
    }
  }

  &__card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  .__active {
    transform: rotate(-90deg);
  }

  &__table {

    &__btn {
      display: inline-block;
      transform: rotate(90deg);
      font-stretch: ultra-condensed;
      line-height: 0px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: rgba(#2eb85c, 0.2);
      }
    }

    tbody td {
      vertical-align: middle;

      &:nth-child(2) {
        width: 160px;
      }

      p{
        margin: 0;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}
.poster img{
  max-width: 80px;
}

@keyframes showSubTableAnim {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
</style>
