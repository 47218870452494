<template>
  <CModal
    centered
    size="lg"
    :closeOnBackdrop="true"
    :show="show"
    :title="title"
    @update:show="close"
    class="PlayerModal"
  >
    <VideoPlayer
      :video="video"
      :height="400"
      :autoplay="true"
      :controls="true"
      ></VideoPlayer>
    <br>
    <CInput
      disabled
      :value="video.playlist"
      :label="$t('lk.videoPublic.url')"
      type="text"
    />
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import VideoPlayer from '@/components/videoEncrypt/VideoPlayer'
export default {
  name: 'PlayerPublicModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    video: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    VideoPlayer
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
};
</script>

<style lang="scss" scoped>
.PlayerModal{
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
.media {
      width: 100%;
      border: none;
      min-height: 31vw;
      margin-top: 24px;
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        min-height: 31vw;
      }
    }
</style>
// <style lang="scss">
// .video-player > div {
//   width: 100% !important;
// }
// </style>
